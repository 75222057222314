// import { createWebHashHistory , createRouter } from 'vue-router';
import { createWebHistory , createRouter } from 'vue-router';
import Home from '@/view/Home.vue';
// import FirstAdvertising from '@/view/FirstAdvertising.vue';
import Administrator from './view/Administrator.vue';
import ProjectForms from '@/landing-page/ProjectForms.vue';
import login from '@/landing-page/login.vue';
import Json from './view/jsonAgain.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta:{
			title:"עמוד הבית",
		},
	},
	{
		path: '/Json',
		name: 'Json',
		component: Json,
		meta:{
			title:"Json",
		},
	},
	{
		path: '/site/:ProjectName',
		name: 'ProjectForms',
		component: ProjectForms,
		meta:{
			title:"ProjectForms",
		},
	},
	{
		path: '/:RouteClient/login',
		name: 'login',
		component: login,
		meta:{
			title:"התחברות",
		},
	},
	{
		path: '/administrator',
		name: 'administrator',
		component: Administrator,
		meta:{
			title:"Administrator",
		},
	},
	// {
	// 	path: '/Advertising',
	// 	name: 'FirstAdvertising',
	// 	component: FirstAdvertising,
	// 	meta:{
	// 		title:"דף נחיתה",
	// 	},
	// },
];

const router = createRouter({
	history: createWebHistory(),
	// history: createWebHashHistory(),
	routes,
	
});



router.beforeEach((to, from, next)=>{
	document.title = `${to.meta.title}`;
	// const isAuthenticated = !!localStorage.getItem('auth');
	// if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
	// 	next(from.fullPath);
	// } else {
	// 	next();
	// }
	if (to.params.ProjectName) {
		document.title = to.params.ProjectName;
	  } else {
		document.title = to.meta.title;
	  }
	next();

});



export default router;


