<template>

    <div class="AllContainer">
        <!-- <div class="imgDiv"><img src="@/assets/Logo/hozi-logo.png" alt=""></div> -->
        <div class="FullScreen" title="מסך עריכה מלא">
            <button class="btn btn-primary" @click="this.JsonFullScreen = !this.JsonFullScreen"><i class="bi bi-fullscreen"></i></button>
        </div>
        <div class="Header">
            <h1>עריכת JSON</h1>      
        </div>
        <div class="Bottom">
            <div class="right" v-if="!this.JsonFullScreen">
                <h4>קובץ הג'ייסון: </h4>
                <input type="file" @change="handleInput">
                <!-- <button @click="this.JsonShow = !this.JsonShow">הצגת/הסתרת הקובץ</button> -->
                <pre v-if="this.JsonShow" class="JsonCss">{{ this.JsonString}}</pre>
            </div>
            <div class="left">
                <h4>שינוי הקובץ:</h4>
                <textarea v-if="this.JsonString" class="JsonCss" v-model="this.JsonString"></textarea>
                <button class="btn btn-success" v-if="this.JsonString" @click="SaveJson()"><i class="bi bi-download"></i></button>
                
            </div>
        </div>
        
    </div>
    
    </template>
    
    <script>
    
    export default{
        name:'jsonChanger',
        props:[],
        data(){
            return{
                FileName:null,
                JsonString:null,
                JsonShow:true,
                JsonFullScreen:false
    
            }
        },
        mounted(){
            
        },
        methods:{
            handleInput(event){
                const file = event.target.files[0]
                this.FileName = file.name
                if(file && file.type === 'application/json'){
                    const reader = new FileReader()
                    reader.onload = () =>{
                        this.JsonData = JSON.parse(reader.result)
                        this.JsonString = JSON.stringify(this.JsonData , null ,2)
                    }
                    reader.readAsText(file)
                }else{
                    alert("אנא הכנס קובץ מסוג json בלבד")
                    console.log("the file : " + file)
                    console.log("typeof file : " + file.type)
                }
            },
            SaveJson(){
                const blob = new Blob([this.JsonString] , {type:'application/json'})
                const link = document.createElement('a')
                const url = URL.createObjectURL(blob)
                link.href = url
                link.download = this.FileName
                link.click()
    
                URL.revokeObjectURL(url)
            },
            DownloadPage(){
                const componentCode = document.documentElement.innerHTML;
    
                const blob = new Blob([componentCode] , {type:'text/x-vue'})
                const link = document.createElement('a')
                const url = URL.createObjectURL(blob)
                link.href = url
                link.download = 'JsonChanger'
                link.click()
    
                URL.revokeObjectURL(url)
            }
        }
    }
    </script>
    
    <style scoped>
    
    .AllContainer{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 85%;
        height: 85vh;
        background-color: #181818;
        color: #f0f0f0;
    }

    .imgDiv{
        position: absolute;
        top:1;
        right: 0;
        width: 170px;
        padding: 10px;
    }

    .FullScreen{
        position: absolute;
        top:1;
        left: 0;
        padding: 10px;
    }

    .imgDiv img{
        width: 100%;
    }
    
    .Header{
        border-bottom: 1px solid gainsboro;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 15%;
        width: 70%;
        margin: auto;
    }
    
    .Bottom{
        border: 1px solid none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85%;
        width: 100%;
    }

    .Bottom .left{
        display: flex;
        flex-grow: 1;
    }

    /* .Bottom .right pre{
        margin: 5px 0 0 0;
    } */
    
    .Bottom .right , .Bottom .left{
        border: 1px solid none;
        display: flex;
        flex-direction: column;
        width: 48.5%;
        height: 92%;
        padding: 30px;
        margin: 0px 10px;
        border-radius: 20px;
        background-color: #2c2c2c;
    }
    
    .Bottom .left button{
        margin: 10px auto auto auto;
        width: 100px;
    }
    
    .Bottom .left textarea{
        height: 70%;
    }
    
    .JsonCss{
        direction: ltr;
    }

    
    
    
    </style>